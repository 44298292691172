import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import details1 from '../../assets/images/projects/csv.png'
import project2 from '../../assets/images/projects/project2.jpg'

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="CSVRef" 
                homePageText="Social balance and Monitoring and Evaluation" 
                homePageUrl="/social-balance-monitoring-evaluation" 
                activePageText="CSVRef" 
            />
            <CaseStudiesDetailsContent />
            <Footer />
        </Layout>
    );
}
export default CaseDetails

const CaseStudiesSidebar = () => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Partner:</span>
                        <a href="https://www.csvlombardia.it/monza-e-brianza/" target="_blank" rel="noreferrer">CSV Monza Lecco Sondrio</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
	    		Italy
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-purchase-tag'></i>
                        </div>
                        <span>Technology:</span>
                       Dino 
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-money'></i>
                        </div>
                        <span>Donor:</span>
	    		Protezione Civile
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-time'></i>
                        </div>
                        <span>Duration:</span>
                        1 year
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-check'></i>
                        </div>
                        <span>Status</span>
                            On going
                    </li>
                </ul>
            </div>
        </div>
    )
}

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={details1} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Digital Transformation</span>
                            <h3>The project</h3>
                            <p>
	    Implementation of the impact evaluation activity within the initiative of widespread reception in the national territory for the benefit of people from Ukraine, presented by the Association Centro di Servizio per il Volontariato di Monza Lecco Sondrio E.T.S. (CSV) in response to the request for expressions of interest, published by the Department of Civil Protection (Ocdpc n. 881 of March 29, 2022).<br/>
An approach based on the participation of all relevant stakeholders (people received, project partners, local authorities) will be used, through a path that starts from a sharing of the objectives of the evaluation exercise and arrives at the measurement of the impact so defined.<br/><br/>
Data will be collected on the DINO platform, developed by Gnucoop.

			    </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div>
                </div>
	                </div>
        </section>
    )
}

